import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import './MembershipPage.css';

function MembershipPage() {
  return (
    <div className="membership-page">
      <h1>Mitgliedschaft</h1>
      <p>
        Mitglied werde ist ganz einfach. Aufnahmeantrag ausfüllen und beim
        nächsten Training oder der Veranstaltung bei uns abgeben.
      </p>
      <Button
        target="_blank"
        href="aufnahmeantrag_2025.pdf"
        className="download-button"
      >
        Aufnahmeantrag herunterladen <FontAwesomeIcon icon={faFileArrowDown} />
      </Button>

      <h2>Kündigung der Mitgliedschaft</h2>
      <p>
        Der Austritt aus dem Verein ist zum Ende eines Kalenderjahres unter
        Einhaltung einer Frist von 3 Monaten möglich{' '}
        <i>(§6 Abs. 3 Vereinssatzung)</i>. Die Kündigung hat schriftlich
        gegenüber dem Vorstand zu erfolgen.
      </p>
    </div>
  );
}

export default MembershipPage;
