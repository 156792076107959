import { format, compareAsc, parse } from 'date-fns';

export function getEventRange(
  start: Date | null | undefined | string,
  end: Date | null | undefined | string,
  allDay: boolean | undefined | string,
) {
  if (typeof start === 'string' || start instanceof String) {
    start = parse(
      start as string,
      allDay ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss",
      new Date(),
    );
  }
  if (typeof end === 'string' || end instanceof String) {
    end = parse(
      end as string,
      allDay ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss",
      new Date(),
    );
  }

  var eventRange = '';
  if (start && start instanceof Date && end && end instanceof Date) {
    if (allDay) {
      var normalizedEnd = new Date(end);
      normalizedEnd.setDate(end.getDate() - 1);
      if (compareAsc(start, normalizedEnd) === 0) {
        eventRange = format(start, 'dd.MM.yyyy');
      } else {
        eventRange =
          format(start, 'dd.MM.yyyy') +
          ' - ' +
          format(normalizedEnd, 'dd.MM.yyyy');
      }
    } else {
      var compStart = new Date(start);
      compStart.setHours(0);
      compStart.setMinutes(0);
      compStart.setSeconds(0);
      compStart.setMilliseconds(0);
      var compEnd = new Date(end);
      compEnd.setHours(0);
      compEnd.setMinutes(0);
      compEnd.setSeconds(0);
      compEnd.setMilliseconds(0);

      if (compareAsc(compStart, compEnd) === 0) {
        eventRange =
          format(start, 'dd.MM.yyyy (HH:mm') + ' - ' + format(end, 'HH:mm)');
      }
    }
  }
  return eventRange;
}

export function isEventOver(
  end: Date | null | undefined | string,
  allDay: boolean | undefined | string,
) {
  if (typeof end === 'string' || end instanceof String) {
    end = parse(
      end as string,
      allDay ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss",
      new Date(),
    );
  }
  if (end && end instanceof Date) {
    if (allDay) {
      var normalizedEnd = new Date(end);
      normalizedEnd.setDate(end.getDate() - 1);
      if (compareAsc(normalizedEnd, new Date()) < 0) {
        return true;
      }
    } else {
      if (compareAsc(end, new Date()) < 0) {
        return true;
      }
    }
  }
  return false;
}
