import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import Logo from './Logo';
import PaddleNav from './components/PaddleNav/PaddleNavGroup';
//import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import useBreakpoint, { Breakpoint } from './components/Utils/UseBreakpoint';
import { getTrainingDates } from './components/Utils/TrainingDates';

function App() {
  const navigate = useNavigate();
  const currentBreakpoint = useBreakpoint();

  const getDefaultActiveKey = (currentBreakpoint: Breakpoint): string[] => {
    switch (currentBreakpoint) {
      case 'xs':
        return [];
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
      case 'xxl':
      default:
        return ['0'];
    }
  };

  return (
    <>
      <Container fluid>
        <Row
          style={{
            paddingBottom: '10px',
            paddingTop: '10px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <Col>
            <div
              style={{
                width: '20%',
                maxWidth: '250px',
                minWidth: '150px',
                float: 'right',
                marginRight: '25px',
              }}
              className="logo"
              onClick={() => navigate('')}
            >
              <Logo />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid="md">
        <Row>
          <Col
            style={{ marginTop: '10px' }}
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 3, order: 2 }}
            lg={{ span: 2, order: 1 }}
            xl={{ span: 2, order: 1 }}
            xxl={{ span: 2, order: 1 }}
          >
            <PaddleNav />
          </Col>
          <Col
            style={{ marginTop: '10px' }}
            xs={{ span: 12, order: 3 }}
            sm={{ span: 12, order: 3 }}
            md={{ span: 9, order: 2 }}
            lg={{ span: 7, order: 2 }}
            xl={{ span: 7, order: 2 }}
            xxl={{ span: 7, order: 2 }}
          >
            <Outlet />
          </Col>
          <Col
            style={{ marginTop: '10px' }}
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 3, order: 3 }}
            xl={{ span: 3, order: 3 }}
            xxl={{ span: 3, order: 3 }}
          >
            <div className="newsPanel">
              <Accordion
                defaultActiveKey={getDefaultActiveKey(currentBreakpoint)}
                alwaysOpen
              >
                {/*<Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Mitgliederversammlung / Fahrtenplanung 2025
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Die Mitgliederversammlung und Fahrtenplanung 2025 findet
                      am Sonntag, den{' '}
                      <b>02.02.2025 - ab 14:00 Uhr im Bootshaus</b> statt{' '}
                      <NavLink to="aktuelles">
                        <i>(siehe Aktuelles)</i>
                      </NavLink>
                      .
                    </p>
                  </Accordion.Body>
                </Accordion.Item>*/}
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Hallenbadtraining</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Das Training im Rebmeerbad Bad Bergzabern
                      (Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern) findet
                      aktuell{' '}
                      <b>
                        alle zwei Wochen Samstags von 15:00 - ca. 16:45 Uhr
                        statt. <u>Treffpunkt ist 14:45 Uhr</u> vor dem Eingang
                        zum Hallenbad.
                      </b>
                      <br />
                      Die nächsten Termine sind der <b>{getTrainingDates()}</b>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                {/*                 <Accordion.Item eventKey="1">
                  <Accordion.Header>Freibadtraining</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Das Training im Rebmeerbad Bad Bergzabern{' '}
                      <i>(Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern)</i>{' '}
                      findet aktuell{' '}
                      <b>
                        wöchentlich Mittwoch von 19:30 - 20:30 Uhr statt.
                        Treffpunkt ist 19:00 Uhr vor dem Eingang zum Freibad.
                      </b>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>  */}
              </Accordion>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="footer">
              <div onClick={() => navigate('impressum')}>Impressum</div>
              <p>Copyrigth&copy; 1990 - {new Date().getFullYear()}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
