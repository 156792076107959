import React, { useState, useEffect } from 'react';
import PaddleNavItem, { Label } from './PaddleNavItem';
import { useNavigate, useLocation } from 'react-router-dom';
import useBreakpoint, { Breakpoint } from '../Utils/UseBreakpoint';

import './PaddleNav.css';

type Props = {};

type SiteRoute =
  | 'aktuelles'
  | 'termine'
  | 'mitgliedschaft'
  | 'kontakt'
  | 'vorstand'
  | 'beitraege'
  | 'impressum';

type RoutesLabelMap = Record<SiteRoute, Label>;

const availibleRoutes: SiteRoute[] = [
  'aktuelles',
  'termine',
  'mitgliedschaft',
  'kontakt',
  'vorstand',
  'beitraege',
  'impressum',
];

const routeLabels: RoutesLabelMap = {
  aktuelles: 'Aktuelles',
  termine: 'Termine',
  mitgliedschaft: {
    main: 'Mitglied',
    sub: 'werden',
  },
  kontakt: 'Kontakt',
  vorstand: 'Vorstand',
  beitraege: 'Beiträge',
  impressum: 'Impressum',
};

function PaddleNav(props: Props) {
  const navigate = useNavigate();
  const currentBreakpoint = useBreakpoint();
  let location = useLocation();

  const [currentRoute, setCurrentRoute] = useState(location.pathname.slice(1));

  useEffect(() => {
    setCurrentRoute(location.pathname.slice(1));
  }, [location]);

  const nav = (route: string): void => {
    //setCurrentRoute(route);
    navigate(route);
  };

  const getDirection = (currentBreakpoint: Breakpoint): string => {
    switch (currentBreakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return 'row';
      case 'lg':
      case 'xl':
      case 'xxl':
      default:
        return 'column';
    }
  };

  return (
    <div
      style={
        {
          display: 'flex',
          flexDirection: getDirection(currentBreakpoint),
          flexWrap: 'wrap',
          marginBottom: '20px',
        } as React.CSSProperties
      }
    >
      {availibleRoutes.map((route) => (
        <PaddleNavItem
          key={route}
          route={route}
          label={routeLabels[route]}
          active={route === currentRoute}
          routeFunc={nav}
        />
      ))}
    </div>
  );
}

export default PaddleNav;
