import { compareAsc, parse } from 'date-fns';

function getCompareDate(): Date {
  var today = new Date();
  today.setDate(today.getDate() - 1);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
}

export function getTrainingDates() {
  var trainingDates = ['05.04.2025', '26.04.2025', '10.05.2025', '24.05.2025'];

  var result = '';

  for (var i = 0; i < trainingDates.length; i++) {
    if (
      compareAsc(
        parse(trainingDates[i], 'dd.MM.yyyy', new Date()),
        getCompareDate(),
      ) > 0
    ) {
      result += trainingDates[i];
      if (i < trainingDates.length - 1) {
        result += ', ';
      }
    }
  }

  return result;
}
