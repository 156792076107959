import { getTrainingDates } from '../components/Utils/TrainingDates';
import './NewsPage.css';

function NewsPage() {
  return (
    <div className="news-page">
      <h2>Aktuelles</h2>

      <h3>Trainingsbetrieb (Winter 2024/25)</h3>
      {/*<p>Auf Grund der anhaltenden technischen Probleme des Freibads des Rebmeerbad Bad Bergzabern <i>(Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern)</i> findest das Training aktuell im Schwimmerbecken zu folgenden geänderten Zeiten statt: <b>wöchentlich Mittwoch von 19:30 - 20:30 Uhr statt. Treffpunkt ist 19:00 Uhr vor dem Eingang zum Freibad.</b></p>*/}
      <p>
        Das Hallenbadtraining im Rebmeerbad Bad Bergzabern
        (Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern) findet aktuell{' '}
        <b>
          alle zwei Wochen Samstags von 15:00 - ca. 16:45 Uhr statt.{' '}
          <u>Treffpunkt ist 14:45 Uhr</u> vor dem Eingang zum Hallenbad.
        </b>
        <br />
        Die nächsten Termine sind der <b>{getTrainingDates()}</b>
      </p>

      {/*       <h3>Mitgliederversammlung mit Fahrtenplanung 2025</h3>
      <p>
        Die Mitgliederversammlung und Fahrtenplanung 2025 findet am Sonntag, den{' '}
        <b>02.02.2025 - ab 14:00 Uhr im Bootshaus</b> statt.
        <br />
        Die Einladung hierzu wurde bereits per E-Mail versendet.
        <br />
        <b>
          {' '}
          Solltest du keine E-Mail erhalten haben, sind eventuell deine
          Kontaktdaten nicht mehr aktuell oder der Verein hat noch keine E-Mail
          - Adesse von dir. Bitte melde dich in diesem Fall unter:{' '}
          <a href="mailto:vorstand@kanuverein-suew.de">
            mailto:vorstand@kanuverein-suew.de
          </a>
        </b>
        <br />
        <i>
          Können wir dich nicht per E-Mail zur Mitgleiderversammlung einladen,
          müssen wir dies per Post tuen. Hierdurch entstehen dem Verein
          zusätzlicher finanzieller und organisatorischer Aufwand.
        </i>
      </p> */}
    </div>
  );
}

export default NewsPage;
