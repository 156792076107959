import './EventsPage.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import React, { useState, useEffect } from 'react';
import { EventInput, EventClickArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import EventModal from '../components/EventsPage/EventModal';
import { getEventRange, isEventOver } from '../components/Utils/EventUtil';
import { parse } from 'date-fns';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

function EventsPage() {
  const [events, setEvents] = useState<EventInput>([]);
  const [selectedEvent, selectEvent] = useState<EventImpl>();
  const [showPastEvents, setShowPastEvents] = useState<boolean>(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    var eventData = [
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-01-11T14:45:00',
        end: '2025-01-11T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-02-08T14:45:00',
        end: '2025-02-08T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-02-22T14:45:00',
        end: '2025-02-22T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-03-22T14:45:00',
        end: '2025-03-22T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-04-05T14:45:00',
        end: '2025-04-05T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-04-26T14:45:00',
        end: '2025-04-26T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-05-10T14:45:00',
        end: '2025-05-10T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Training',
        description: 'Treffpunkt ist 14:45 Uhr vor dem Eingang zum Hallenbad.',
        contactPerson: 'Max Reinhardt, Frederik Haak',
        location: 'Rebmeerbad, Friedrich-Ebert-Straße 40, 76887 Bad Bergzabern',
        start: '2025-05-24T14:45:00',
        end: '2025-05-24T16:45:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Hüningen',
        description:
          'Traningsfahrt an den Wildwasserkanal in Huningue (Parc des Eaux Vives), 09:00 Uhr Treffen am Bootshaus.',
        contactPerson: 'Ole Wagenbrenner',
        location: 'Huningue (Frankreich)',
        start: '2025-04-06',
        end: '2025-04-07',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Mitgliederversammlung',
        description:
          'Jährliche Mitgliederversammlung des Kanuverein Südliche Weinstraße e.V.',
        contactPerson: 'Max Reinhardt',
        location: 'Bootshaus, Auf dem Viertel 11, 76887 Bad Bergzabern',
        start: '2025-02-02T14:00:00',
        end: '2025-02-02T16:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Fahrtenplanung 2025',
        description: 'Fahrtenplanung für das Jahr 2025.',
        contactPerson: 'Max Reinhardt',
        location: 'Bootshaus, Auf dem Viertel 11, 76887 Bad Bergzabern',
        start: '2025-02-02T16:00:00',
        end: '2025-02-02T17:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Mitgliederversammlung',
        description:
          'Jährliche Mitgliederversammlung des Kanuverein Südliche Weinstraße e.V.',
        contactPerson: 'Max Reinhardt',
        location: 'Bootshaus, Auf dem Viertel 11, 76887 Bad Bergzabern',
        start: '2026-02-01T14:00:00',
        end: '2026-02-01T16:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Fahrtenplanung 2026',
        description: 'Fahrtenplanung für das Jahr 2026.',
        contactPerson: 'Max Reinhardt',
        location: 'Bootshaus, Auf dem Viertel 11, 76887 Bad Bergzabern',
        start: '2026-02-01T16:00:00',
        end: '2026-02-01T17:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Moderfahrt',
        description: '10:00 Uhr Treffen in Drusenheim am Parkplatz',
        contactPerson: 'Julian Fang (01737733622)',
        location: 'Drusenheim - Stattmatten',
        start: '2025-05-01T10:00:00',
        end: '2025-05-01T17:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: '(Familien-)Wanderfahrt auf der Ill',
        description: '',
        contactPerson: 'Julian Fang (01737733622)',
        location: 'Fuchs am Buckel - Unter der Brücke',
        start: '2025-05-18',
        end: '2025-05-19',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Donau',
        description: 'Mittelalterfest und Paddeln auf der Donau.',
        contactPerson: 'Julian Fang (01737733622)',
        location: 'Zeltplatz Neuburg an der Donau (DJCN)',
        start: '2025-06-27',
        end: '2025-06-30',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Aktionstage mit dem Kinderheim',
        //description: '',
        contactPerson: 'Susi Dueborgdioe',
        location:
          'Neuburg am See am 16.08.2025 (11 Uhr), Moder am 17.08.2025 (ca. 12 Uhr)',
        start: '2025-08-16',
        end: '2025-08-18',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Wanderung',
        description:
          'Verköstigung aus dem Dutch Oven, Salat- und Kuchenspenden sind erforderlich.',
        contactPerson: 'Helmut Baaden',
        location: 'Reisdorf',
        start: '2025-03-09T11:00:00',
        end: '2025-03-09T16:00:00',
        allDay: false,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Wieslauterreinigung',
        description:
          'Jährliche Müllsammelaktion an der Wieslauter in Zusammenarbeit mit den Naturschutzverbänden und Anglern.',
        contactPerson: 'Helmut Baaden',
        location: 'Bundenthal',
        start: '2025-10-04',
        end: '2025-10-05',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Kanu Freestyle Trainingslager und Freizeit (Ostern)',
        description:
          'Teilnehmen können Kids mit und ohne Begleitung, die ins Kanu Freestyle einsteigen wollen. Kids müssen Sicher im Boot sitzen und rollen können. Teilnahme mit eigenen Boot oder nach Absprache können passende Freestyle Boote ausgeliehen werden. Programm: Boot und Material im Freestyle, Sicherheit und Prävention, Fahren im Wildwasser und Welle, grundlegende Moves auf dem Flachwasser und in der Welle.',
        location: 'St. Pierre de Boeuf (Frankreich)',
        contactPerson: 'Leon Bast',
        start: '2025-04-12',
        end: '2025-04-20',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Osterfahrt an die Ardèche',
        description:
          'Osterfahrt an die Ardèche/Beaume, geeignet für Anfänger und Fortgeschrittene des Kanusports. In der Kernzeit vom 18.04. - 25.04.2025 wird das Vereinszelt aufgebaut und in der großen Gruppe gepaddelt, gekocht und gelacht.',
        location:
          'Domaine Arleblanc Camping, 2720 route de la Charve, 07260 Rosieres',
        contactPerson: 'Susi Dueborgdioe',
        start: '2025-04-12',
        end: '2025-04-27',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Youth Freestyle Kayak Camp',
        description: '',
        location: 'Plattling (Deutschland)',
        contactPerson: 'Leon Bast',
        start: '2025-05-01',
        end: '2025-05-05',
        allDay: true,
        backgroundColor: '#f1ab2a',
        borderColor: '#e79807',
        textColor: '#000',
      },
      {
        title: 'Kanu Freestyle Trainingslager und Freizeit (Sommer)',
        description:
          'Teilnehmen können Kids mit und ohne Begleitung, die ins Kanu Freestyle einsteigen wollen. Kids müssen Sicher im Boot sitzen und rollen können. Teilnahme mit eigenen Boot oder nach Absprache können passende Freestyle Boote ausgeliehen werden. Programm: Boot und Material im Freestyle, Sicherheit und Prävention, Fahren im Wildwasser und Welle, grundlegende Moves auf dem Flachwasser und in der Welle.',
        location: 'St. Pierre de Boeuf (Frankreich)',
        contactPerson: 'Leon Bast',
        start: '2025-09-06',
        end: '2025-09-14',
        allDay: true,
        backgroundColor: '#0082cb',
      },
      {
        title: 'Kanu Freestyle Trainingslager und Freizeit (Herbst)',
        description:
          'Teilnehmen können Kids mit und ohne Begleitung, die ins Kanu Freestyle einsteigen wollen. Kids müssen Sicher im Boot sitzen und rollen können. Teilnahme mit eigenen Boot oder nach Absprache können passende Freestyle Boote ausgeliehen werden. Programm: Boot und Material im Freestyle, Sicherheit und Prävention, Fahren im Wildwasser und Welle, grundlegende Moves auf dem Flachwasser und in der Welle.',
        location: 'St. Pierre de Boeuf (Frankreich)',
        contactPerson: 'Leon Bast',
        start: '2025-10-25',
        end: '2025-11-02',
        allDay: true,
        backgroundColor: '#0082cb',
      },
    ].sort((a, b) => {
      var startA = parse(
        a.start,
        a.allDay ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss",
        new Date(),
      );
      var startB = parse(
        b.start,
        b.allDay ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss",
        new Date(),
      );

      return startA > startB ? 1 : -1;
    });
    setEvents(eventData);
  }, []);

  function eventClickCalendar(eventClickInfo: EventClickArg) {
    showModal(eventClickInfo.event);
  }

  /*   function eventClickRow(event: any) {
    showModal(event);
  } */

  function showModal(event: any) {
    selectEvent(event);
    handleShow();
  }

  return (
    <div>
      <h2>Fahrten und Aktionen</h2>

      <FullCalendar
        plugins={[dayGridPlugin, bootstrap5Plugin, multiMonthPlugin]}
        initialView="dayGridMonth"
        themeSystem="bootstrap5"
        locale="de"
        height="auto"
        firstDay={1}
        buttonText={{
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
          list: 'Liste',
          year: 'Jahr',
        }}
        headerToolbar={{
          left: 'prev,next,today',
          center: 'title',
          right: 'dayGridMonth,multiMonthYear',
        }}
        eventClick={eventClickCalendar}
        events={events}
      />

      <h2>Übersicht:</h2>
      <Form className="show-past-events-checkbox">
        <Form.Check
          type="checkbox"
          id="show-past-events-checkbox"
          checked={showPastEvents}
          onChange={(value) => {
            setShowPastEvents(value.currentTarget.checked);
          }}
          label="Vergangene Veranstaltungen anzeigen"
        />
      </Form>

      <Table hover bordered responsive className="events-table">
        <tbody>
          {showPastEvents &&
            events.map((event: any) => (
              <EventTableRow
                key={event.start + event.end}
                event={event}
                showPast={true}
              ></EventTableRow>
            ))}
          {events.map((event: any) => (
            <EventTableRow
              key={event.start + event.end}
              event={event}
              showPast={false}
            ></EventTableRow>
          ))}
        </tbody>
      </Table>

      <EventModal
        show={show}
        handleClose={handleClose}
        event={selectedEvent}
      ></EventModal>
    </div>
  );

  function EventTableRow(props: EventTableRowProps) {
    const { event, showPast } = props;
    var eventsSelector = showPast
      ? !isEventOver(event.end, event.allDay)
      : isEventOver(event.end, event.allDay);

    if (eventsSelector) {
      return null;
    }
    return (
      <tr
        className={showPast ? 'past-event' : 'current-event'}
        onClick={() => {
          //eventClickRow(event);//todo: fix
        }}
      >
        <td>{getEventRange(event.start, event.end, event.allDay)}</td>
        <td>{event.title}</td>
        {event.contactPerson ? <td>{event.contactPerson}</td> : <td></td>}
      </tr>
    );
  }
}

export default EventsPage;

type EventTableRowProps = {
  event: any;
  showPast: boolean;
};
